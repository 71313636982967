import { useParams } from 'react-router-dom';
import { useWatch } from 'react-hook-form';

import { useIsFlowDraft } from './useIsFlowDraft';

export function useIsFlowDuplicate() {
  const { mode } = useParams();

  const duplicateLinkId = useWatch({ name: 'duplicateLinkId' });
  const isDuplicatedLink = mode === 'duplicate' || !!duplicateLinkId;
  const isDraft = useIsFlowDraft();

  return isDuplicatedLink || !!(isDuplicatedLink && isDraft);
}
